const routes = [ 
  { path: "/", redirect: { path: "/login" } },
  {
    path: "/",
    component: () => import("layouts/AuthLayout.vue"),
    name: "auth-layout",
    children: [
      {
        path: "/login",
        name: "login",
        component: () => import("pages/Login/Index.vue"),
      },
      {
        path: "/reset",
        name: "reset",
        component: () => import("pages/Login/Reset.vue"),
      },
      {
        path: "/password",
        name: "password",
        props: true,
        component: () => import("pages/AlterarSenha/Index.vue"),
      },
      {
        path: "/report",
        name: "report",
        props: true,
        component: () => import("pages/Relatorio/Report.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("layouts/MainLayout.vue"),
    name: "main-layout",
    children: [
      {
        path: "/home",
        name: "home",
        meta: { title: "Home" },
        component: () => import("pages/Index.vue"),
      },
      {
        path: "/projetos",
        name: "projetos",
        meta: { title: "projetos" },
        component: () => import("pages/Projetos/Index.vue"),
      },
      {
        path: "/projeto",
        name: "projeto",
        props: true,
        meta: { title: "Projeto" },
        component: () => import("pages/Projetos/Projeto.vue"),
      },
      {
        path: "/atividade",
        name: "atividade",
        props: true,
        meta: { title: "Atividade" },
        component: () => import("pages/Atividades/Atividade.vue"),
      },
      {
        path: "/objetivo",
        name: "objetivo",
        props: true,
        meta: { title: "Objetivo" },
        component: () => import("pages/Objetivos/Objetivo.vue"),
      },
      {
        path: "/arquivos",
        name: "arquivos",
        props: true,
        meta: { title: "Arquivos" },
        component: () => import("src/pages/Arquivos/Index.vue"),
      },
      {
        path: "/arquivo",
        name: "arquivo",
        props: true,
        meta: { title: "Arquivo" },
        component: () => import("pages/Arquivos/Arquivo.vue"),
      },
      {
        path: "/sprints",
        name: "sprints",
        props: true,
        meta: { title: "Sprints" },
        component: () => import("src/pages/Sprints/Index.vue"),
      },
      {
        path: "/sprint",
        name: "sprint",
        props: true,
        meta: { title: "Sprint" },
        component: () => import("pages/Sprints/Sprint.vue"),
      },
      {
        path: "/pessoas",
        name: "pessoas",
        props: true,
        meta: { title: "Pessoas" },
        component: () => import("src/pages/Pessoas/Index.vue"),
      },
      {
        path: "/pessoa",
        name: "pessoa",
        props: true,
        meta: { title: "Pessoa" },
        component: () => import("pages/Pessoas/Pessoa.vue"),
      },
      {
        path: "/pdf",
        name: "pdf",
        props: true,
        meta: { title: "Visualizador" },
        component: () => import("pages/Arquivos/PDFViewer.vue"),
      },
      {
        path: "/usuarios",
        name: "usuarios",
        props: true,
        meta: { title: "Usuarios" },
        component: () => import("src/pages/Usuarios/Index.vue"),
      },
      {
        path: "/usuario",
        name: "usuario",
        props: true,
        meta: { title: "Usuario" },
        component: () => import("pages/Usuarios/Usuario.vue"),
      },
      {
        path: "/equipamentos",
        name: "equipamentos",
        props: true,
        meta: { title: "Equipamentos" },
        component: () => import("src/pages/Equipamentos/Index.vue"),
      },
      {
        path: "/equipamento",
        name: "equipamento",
        props: true,
        meta: { title: "Equipamento" },
        component: () => import("pages/Equipamentos/Equipamento.vue"),
      },
      {
        path: "/insumos",
        name: "insumos",
        props: true,
        meta: { title: "Insumos" },
        component: () => import("src/pages/Insumos/Index.vue"),
      },
      {
        path: "/insumo",
        name: "insumo",
        props: true,
        meta: { title: "Insumo" },
        component: () => import("pages/Insumos/Insumo.vue"),
      },
      {
        path: "/entradas",
        name: "entradas",
        props: true,
        meta: { title: "Entradas" },
        component: () => import("pages/Entradas/Index.vue"),
      },
      {
        path: "/entrada",
        name: "entrada",
        props: true,
        meta: { title: "Entrada" },
        component: () => import("pages/Entradas/Entrada.vue"),
      },
      {
        path: "/despesas",
        name: "despesas",
        props: true,
        meta: { title: "Despesas" },
        component: () => import("pages/Despesas/Index.vue"),
      },
      {
        path: "/despesa",
        name: "despesa",
        props: true,
        meta: { title: "Despesa" },
        component: () => import("pages/Despesas/Despesa.vue"),
      },
      {
        path: "/relatorios",
        name: "relatorios",
        props: true,
        meta: { title: "Relatórios" },
        component: () => import("pages/Relatorio/Index.vue"),
      },
      {
        path: "/perfil",
        name: "perfil",
        props: true,
        meta: { title: "Meu perfil" },
        component: () => import("pages/Perfil/Index.vue"),
      },
    ],
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: "/:catchAll(.*)*",
    component: () => import("pages/Error404.vue"),
  },
];

export default routes;
